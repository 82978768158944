import { useState, useEffect } from 'react';

export const useAlert = () => {
  const [alert, setAlert] = useState({
    type: 'info' as 'info' | 'success' | 'warning' | 'error',
    message: '',
    visible: false,
  });

  const showAlert = (type: 'info' | 'success' | 'warning' | 'error', message: string) => {
    setAlert({ type, message, visible: true });
  };

  const hideAlert = () => {
    setAlert((prev) => ({ ...prev, visible: false }));
  };

  useEffect(() => {
    if (alert.visible) {
      const timer = setTimeout(() => {
        hideAlert();
      }, 3000); // Automatically hide after 3 seconds

      return () => clearTimeout(timer); // Cleanup timer if alert changes
    }
  }, [alert.visible]);

  return { alert, showAlert, hideAlert };
};
